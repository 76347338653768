import { template as template_ae3405b1bf8b4432bea2094c4a992523 } from "@ember/template-compiler";
const WelcomeHeader = template_ae3405b1bf8b4432bea2094c4a992523(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
