import { template as template_b3fdd9e9e1bf4c3db8d5665ed278a751 } from "@ember/template-compiler";
const FKText = template_b3fdd9e9e1bf4c3db8d5665ed278a751(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
